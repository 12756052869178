import * as React from "react"
import Layout from "../components/layout"
import { Link } from 'gatsby'

const LegalPage = () => {
  return (
    <Layout pageTitle="利用規約 / 法的留意及び開示事項">
      <article className="nomal-page">
        <h1>Legal Disclaimer</h1>
        <h2 className="h2">利用規約 / 法的留意及び開示事項</h2>
        <section>
          <br/>
          <p>本ウェブサイトを利用することにより、以下の利用規約及び法的遵守事項に合意したものとみなします。利用者は、本規約の内容を修正・改変・条件を付与することなく、全ての事項を完全に承諾するものとします。Horiko Capital Management LLC （以下"HCM"）は本規約を、いつでも通知なく変更、修正する権利を留保します。</p>
        </section>
        <section>
          <br/>
          <p>著作権及び所有権</p>
          <p>HCMが本ウェブサイト及び本ウェブサイト上で提供される情報（以下「本件情報」といいます）の著作権を有し、運営管理を行っています。本件情報を保存、複製した場合であっても、著作権はHCMに帰属します。本件情報は私的利用目的に限り提供されるものであって、第三者に提供する目的で、その形式・フォーマットにかかわらず、全部であると一部であるとにかかわらず、出版、配信・配布、または複製することを禁じます。</p>
        </section>
        <section>
          <br/>
          <p>ファンド及び有価証券の非勧誘</p>
          <p>本ウェブサイト及び本件情報は、HCMによって運用されているファンド及び有価証券の取引を推奨する目的、または勧誘する目的で提供されるものではありません。HCMが運用するファンドへの申し込み及び勧誘は、私募目論見書(Confidential Private Offering Memorandum)によってのみ行われます。ファンドに関する情報の閲覧は、1933年制定の米国証券法501条に規定された「適格投資家」(Accredited Investor)、及び金融及び商取引に十分な知識と経験があり、投資のリスクとリターンを評価できる投資家に限定されています。</p>
        </section>
        <section>
          <br/>
          <p>保証とその制限</p>
          <p>本件情報、及びサービスには期限切れであるものや不完全、不正確、その他エラーがある可能性があります。利用者とHCMとの契約の中で明示的に別段の定めをした場合を除き、全ての情報、及びサービスはなんら保証のない“現状有姿のままで”提供されます。HCMは本情報、サービスに関して、明示であると黙示であるとを問わず、商品性及び特定目的に対する適合性に関する黙示の保証を含む一切の保証を行うものではありません。HCMは 本サイトへの接続又は使用に起因して、利用者あるいは第三者が被るいかなる直接的、間接的、付随的、特別的、結果的損害又は利益の損失、データの消失、使用不能の損害に関して契約違反であると、不法行為であるとを問わず、一切責任を負いません。HCMは本ウェブサイトを、いつでも通知なく変更、修正する権利を留保します。 英文と和文の利用規約で相違がある場合は、<Link to="/en/legal" className="underline">英文利用規約</Link> の内容が優先されます。</p>
        </section>
        <section>
          <br/>
          <p>準拠法及び裁判管轄</p>
          <p>利用者の本サイトへの接続、使用に関する全ての事柄は米国及びニューヨーク州の法律に準拠します。</p>
        </section>
      </article>
    </Layout>
  )
}

export default LegalPage
